/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Poppins - Regular */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Poppins - Light */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Poppins - Medium */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Poppins - SemiBold */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Poppins - Bold */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Poppins - ExtraBold */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Poppins - Black */
@font-face {
  font-family: "Poppins";
  src: url("./assets/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Define class names for different font weights */
.font-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.font-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.font-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.font-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

.font-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
}

header {
  position: fixed;
  background-color: #ffffff;
  color: #000;
  width: 100%;
  padding: 1rem;
  z-index: 9;
}
.navbar {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.navbar button {
  background: #3fffff;
  padding: 7px 55px;
  border: none;
}
.logo img {
  width: 180px;
  height: 80px;
  margin-right: -10.5rem;
}

.nav-links {
  list-style: none;
  display: flex;
  margin-left: 42%;
}

.nav-links li {
  margin-right: 3rem;
}
.blue {
  color: #0d1282;
}
h2 {
  font-size: 50px;
}
.nav-links li a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 24px;
  text-decoration: none;
}
.navbar-hidden {
  display: none;
}

.banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(40, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide img {
  width: 100%;
  height: auto;
}
.card img {
  width: 100%;
}
.slide-content {
  position: absolute;
  text-align: center; /* Center the text horizontally */
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  /* max-width: 97%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  width: 95%;
  margin: auto;
}

.card {
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
}

.card button {
  text-align: center;
  padding: 2% 10%;
  border: none;
  color: aliceblue;
  background: #0d1282;
}
.text-center {
  text-align: center;
}
.cards h3 {
  padding: 5%;
  text-align: center;
}
.cards img {
  border-radius: 10px;
  height: 159px;
}
.cards p {
  padding: 2%;

  text-align: center;
}
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
}
/* Cards.css */

/* Cards.css */

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.animated-card {
  transition: transform 0.3s ease-in-out;
}

/* Hover effect */
.animated-card:hover {
  animation-name: scaleUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

/* Hover out effect */
.animated-card:not(:hover) {
  animation-name: scaleDown;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}
/* Add responsive styles here */
/* For example, adjust the layout for smaller screen sizes */

/* For example: */

.bannercard {
  height: 400px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 30px;
  background: #22a699;
  align-content: center;

  background: url(../src/assets/duabi.jpg) center;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}
.display-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-space-around {
  justify-content: space-evenly;
}

svg {
  font-size: 59px;
  margin: 5%;
  padding: 7%;
}
.bannercard p {
  margin: auto;
  width: 60%;
  font-size: 16px;
}
.exp {
  font-size: 39px !important;
}
.bannercard h2 {
  position: relative;
  top: 3%;
}
/* Add this CSS in your style file or use inline styles */
/* Add this CSS in your style file or use inline styles */
.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.left-side {
  width: 50%;
  /* You can adjust the maximum height as needed */
}
.left-side img {
  border-radius: 35%;
  width: 100%;
  /* max-height: 300px; You can adjust the maximum height as needed */
}

.nemorpisam {
  margin: 0px 0px 10px 0px;
  padding: 35px 35px 35px 35px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.05);
}

.right-side {
  flex: 1;
  padding-left: 20px;
}

.right-side h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.right-side p {
  font-size: 16px;
}

.carousel-container {
  max-width: 1200px; /* Adjust the max width as per your design */
  margin: 0 auto;
  padding: 20px;
}
.package-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  background-color: #f9f9f9;
  text-align: center;
}

.package-card img {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.package-card h3 {
  margin: 10px 0;
}

.package-card p {
  color: #555;
  margin: 5px 0;
}

.package-card p.price {
  font-weight: bold;
  color: #00a0e9;
}
.red {
  color: #b00000;
}

/* Define the main container for the about section */
.about-container {
  display: flex;

  align-items: center;
  padding: 50px;
}

/* Style the left-side image */
.about-image {
  width: 30%;
  height: auto;
  border-radius: 10px;
}

/* Style the right-side content */
.about-content {
  width: 70%;
  margin: 2%;
  /* max-width: 600px; */
  text-align: justify;
}

/* Style the company name */
.company-name {
  font-size: 24px;
  font-weight: bold;
}

/* Style the company location */
.company-location {
  font-size: 18px;
  color: #666;
}

/* Style the company description */
.company-description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
}
.company-description h1 {
  font-size: 35px;
}
.company-description h2 {
  font-size: 20px;
}
.company-description p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.postion-realative {
  position: relative !important;
}
img.team-photo {
  width: 220px;
}
.team-gallery {
  justify-content: space-evenly;
}
.zoom-center {
  overflow: hidden;
  border-radius: 50%;
}

.zoom-center img {
  transform-origin: center center;
  transform: scale(
    1.5
  ) !important; /* Adjust the value to control the zoom level (1.0 means no zoom) */
  /* You may also need to set appropriate width and height to fit the zoomed images */
}

.team-member-card {
  text-align: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.team-member-card .team-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.team-member-card .member-name {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* If you want equal spacing between cards, you can use justify-content: space-around; */
}

/* Styling for the service card */
.service-card {
  width: calc(33.33% - 20px); /* Adjust the width as per your design */
  margin-bottom: 20px;
  /* Add other styles as needed */
}

.service-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  /* Add other styles as needed */
}
.con {
  height: 100vh;

  background: linear-gradient(145deg, #dcd4d4, #fffbfb);
  box-shadow: 12px 12px 24px #cfc8c8, -12px -12px 24px #ffffff;
  /* background-color: #071952; */
}
.contact-container {
  display: flex;
  position: relative;
  top: 12%;
  /* border: 1px solid black; */
  padding: 2%;
  width: 90%;
  border-radius: 50px;
  background: linear-gradient(145deg, #dcd4d4, #fffbfb);
  box-shadow: 12px 12px 24px #cfc8c8, -12px -12px 24px #ffffff;
  border-radius: 20px;
  margin: 2% auto;
}

.contact-form {
  width: 60%;
}

.contact-form h2,
.contact-address h2 {
  color: #000000;
  margin-bottom: 10px;
}
.hidden {
  display: none;
}
form {
  /* display: flex; */
  flex-direction: column;
  gap: 10px;
  width: 80%;
  background: #f1f1f1;
  margin: auto 2%;
  border-radius: 16px;
  padding: 6%;
}
label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.contact-address {
  width: 40%;
}
.contact-address img {
  width: 550px;
}
.footer-container {
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo img {
  width: 320px; /* Adjust the width as needed */
}

.footer-links ul {
  list-style: none;
  padding: 0;
  text-align: initial;
  margin: 10px 0;
}

.footer-links li {
  margin: 5px 0;
}
.footer-links h3 {
  text-align: initial;
}
.footer-links a {
  line-height: 3;
  text-decoration: none;
  color: white;
}

.footer-social {
  line-height: 4;
  display: flex;
  margin-top: 10px;
}

.footer-social a {
  margin-right: 32px;

  color: white;
  text-decoration: none;
}

.footer-copyright {
  margin-top: 10px;
  text-align: center;
}
/* Adjust font size and set real colors for social media icons */
.svg-inline--fa.fa-twitter {
  font-size: 25px;
  color: #1da1f2; /* Twitter blue color */
}

.svg-inline--fa.fa-facebook {
  font-size: 25px;
  color: #1877f2; /* Facebook blue color */
}

.svg-inline--fa.fa-instagram {
  font-size: 25px;
  color: #e4405f; /* Instagram pink color */
}
.address {
  font-family: "Poppins", sans-serif;
}
.copy {
  color: white;
  background-color: #000;
  padding: 1%;
}

a {
  color: white;
}

/* Add this to your CSS */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  padding: 10px 15px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
svg.svg-inline--fa.fa-whatsapp {
  margin: -7%-3%;
}
.names {
  text-transform: uppercase;
}

.mobile-nav-links {
  list-style: none;
  padding: 0;
  position: absolute;
  top: 70px; /* Adjust as needed based on your design */
  left: 0;
  width: 100%;
  background-color: #fff; /* Set your desired background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none; /* Hide by default */
}

.mobile-nav-links.show {
  display: block; /* Show when the mobile menu is active */
}

.mobile-nav-links li {
  text-align: center;
  padding: 1rem 0;
}

.mobile-nav-links a {
  text-decoration: none;
  color: #333; /* Set your desired link color */
  font-size: 1.2rem; /* Set your desired font size */
}
/* Media query for responsiveness */
@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-links ul {
    margin: 0;
  }

  .footer-links li {
    margin: 0 10px;
  }

  .footer-social {
    margin: 0;
  }

  .footer-address {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  header {
    position: fixed;
    background-color: #ffffff;
    color: #000;
    width: 100%;
    padding: 1rem;
    z-index: 9;
    justify-content: space-around;
    display: flex;
  }
  .navbar {
    padding: 10px;
  }

  .hamburger {
    display: block;
    flex-direction: column;
    cursor: pointer;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
  }

  .nav-links {
    display: none;
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    right: -100%;
  }
  .nav-links-mobile {
    position: fixed;
    top: 0;
    left: 0;
    /* padding: 14%; */
    width: 100%;
    text-align: initial;
    height: 100vh;
    background-color: #fff;
    z-index: 10;
    line-height: 2;
    font-size: 35px;
  }

  .nav-links-mobile.show {
    transform: translateX(0); /* Bring the menu into view */
  }
  .nav-links-mobile a {
    color: #000;
  }
  .nav-links.show {
    right: 0;
  }
  .hamburger.open div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.open div:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open div:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  .slide-content {
    position: absolute;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    max-width: 65%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
  }
  h2 {
    font-size: 28px;
  }
  .hide {
    display: none;
  }
  .typing-animation {
    overflow: hidden;
    white-space: normal !important;
    animation: fadeIn 3s steps(40, end);
  }
  .cards {
    display: block;
  }
  .about-section {
    display: block;
  }
  .left-side {
    width: 78%;
    margin: auto;
  }
  .bannercard {
    height: 1135px;
  }
  .display-flex {
    display: block;
  }
  .margin-2 {
    margin: 2%;
  }
  .about-content {
    width: 95%;
    margin: 2%;
    max-width: 600px;
    text-align: justify;
  }
  .about-container {
    display: block;
    align-items: center;
    padding: 0px;
  }
  .about-image {
    width: 85%;
    margin: auto 10%;
    height: auto;
    border-radius: 10px;
  }
  .postion-realative {
    position: inherit !important;
  }
  .header2 {
    position: fixed;
    background-color: #ffffff;
    color: #000;
    width: 90%;
    padding: 1rem;
    z-index: 9;
    justify-content: space-between;
    display: flex;
  }
  .contact-container {
    display: block;
  }
  .contact-container {
    position: relative;
    top: 27%;
    /* border: 1px solid black; */
    /* padding: 2%; */
    /* width: 90%; */
    border-radius: 50px;
    /* background: linear-gradient(145deg, #dcd4d4, #fffbfb); */
    /* box-shadow: 12px 12px 24px #cfc8c8, -12px -12px 24px #ffffff; */
    /* border-radius: 20px; */
    /* margin: 2% auto; */
  }
  .contact-form {
    width: 90%;
    margin: auto;
  }
  .con {
    height: 100vh;
    background: linear-gradient(145deg, #ffffff, #ffffff);
    box-shadow: 12px 12px 24px #cfc8c8, -12px -12px 24px #ffffff;
    background-color: #071952;
  }
  .contact-address {
    display: none;
  }
  form {
    background: #f0eaea00;
  }
  .con {
    height: 57vh;
  }
  .company-description h1 {
    font-size: 33px;
  }
  .about-image {
    display: none;
  }
}
